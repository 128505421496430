@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Inter",
        sans-serif;


}

button {
    font-family: "Inter",
        sans-serif !important;
}

.btnPrimary {
    color: white;
    background-color: #DF7A5E;
    border-radius: 8px;
    width: 100%;
    padding: 12px 12px;
    font-size: 18px;
    font-weight: 500;
}

.btnPrimary:hover {
    box-shadow: 4px 4px 8px 0px #00000040;

}

.btnSimple {
    color: #686C82;
    border: 1px solid #686C82;
    border-radius: 8px;
    width: 100%;
    padding: 11px 12px;
    font-size: 18px;
    font-weight: 500;
}

.btnSimple:hover {
    box-shadow: 4px 4px 8px 0px #00000040;

}

.custom-checkbox:checked {
    background-color: #050B33;
    /* Change this to your desired color */
    border-color: #050B33;
    /* Optional: Change border color if needed */
}

/* Optional: If you want to remove the default checkmark */
.custom-checkbox:checked::after {
    display: none;
}
.scrollHide ::-webkit-scrollbar {
    display: none;
}

.scrollHide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}